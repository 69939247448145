<template>
  <Dropdown
    class="lang-dropdown btn btn-ghost no-animation"
    style="border: none; border-radius: 8px"
    v-model="model"
    show-icon
    :config="{ trackBy: 'id', label: 'name' }"
    :options="options"
    :disabled="isLoading"
  >
    <template #before>
      <GlobeIcon
        class="lang-dropdown__icon text-base-content fill-current"
      />
    </template>
    <template #caret>
      <font-awesome-icon icon="chevron-down" />
    </template>
  </Dropdown>
</template>

<script>
// TODO find and use the relevant npm for lang flags instead of custom workaround

import { mapGetters, mapActions } from "vuex";
import CountryFlag from 'vue-country-flag'
import Dropdown from "@shared/components/dropdown-base";
import GlobeIcon from "@shared/assets/icons/globe.svg";
import { defaultLang as defaultLangCode } from "@shared/plugins/i18n";

import { getCountryCodeByLanguage } from "@shared/plugins/i18n/utils";

const defaultLangData = {
  id: "default",
  name: "English",
}

export default {
  name: "language-menu",
  
  components: {
    Dropdown,
    GlobeIcon,
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      currentLang: "LangStore/getCurrentLang",
      langOptions: "LangStore/getUserLangList",
    }),

    defaultLang() {
      return this.langOptions.find((el) => el.code === defaultLangCode) ?? defaultLangData;
    },

    model: {
      get() {
        // eslint-disable-next-line no-unused-vars
        const { icon, ...value } = (this.currentLang || this.defaultLang);
        return value;
      },
      set(val) {
        this.changeLang(val);
      }
    },

    // TODO add flag icons
    options() {
      return this.langOptions
        .map((el) => {
          const countryCode = getCountryCodeByLanguage(el.code);
          return {
            ...el,
            icon: countryCode ? {
              functional: true,
              render: (h) =>
                h("div", { class: "flag-container" }, [
                  h(CountryFlag, { props: { country: countryCode } })
                ])
            } : null,
          }
        })
    }
  },

  methods: {
    ...mapActions({
      setUserLang: "LangStore/setUserLang",
    }),

    async changeLang(lang) {
      this.isLoading = true;
      try {
        await this.setUserLang(lang);
      } catch (error) {
        console.error("Error while changing language", error);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.lang-dropdown {
  &__icon {
    @apply text-base-content;
  }
  ::v-deep {
    .multiselect {
      &__tags {
        font-weight: normal;
        text-transform: capitalize;
        &:hover {
          box-shadow: none;
        }
        svg {
          fill: var(--theme-color-base-content) !important;
        }
      }
      &__option {
        min-height: toRem(34px);
        padding-block: 0;
        &--highlight {
          border: none !important;
        }
      }
      &__content-wrapper {
        max-width: toRem(250px);
        width: auto;
        border-radius: toRem(8px);
      }
    }
    .flag-container {
      display: inline-flex;
      align-items: center;
      padding-bottom: toRem(5px);
    }
  }
}
</style>