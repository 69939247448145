<!-- Localized -->
<template>
	<div
		v-on-click-outside="collapse" 
		class="border-r border-solid border-gray-200 pr-4 "
		@click="expand"
	>
		<div
			class="relative mx-0 rounded transition-all px-3 ease-linear delay-200 min-w-full" 
			:class="(options?.length || !dropdown) ? 'cursor-pointer hover:bg-gray-100' : 'cursor-default'"
			:style="{ pointerEvents: !disabled ? 'auto' : 'none' }"
		>
			<div class="rounded-md flex justify-around items-center">
				<div>
					<div class="text-base-content-300 text-sm tracking-wide pb-2">
						{{ title }}
					</div>
					<div
						class="text-base font-medium tracking-wide text-base-content flex items-center gap-3"
						v-if="value"
					>
						<div
							v-if="value.color"
							class="rounded-full border border-solid border-gray-300 w-4 h-4"
							:class="`bg-${value.color}`"
							:style="{ 'background-color': value.color }"
						/>
						<span
							class="active-state truncate w-32 text-sm"
							:title="value.label"
							:style="getStyle(value)"
						>
							{{ value.label }}
						</span>
					</div>
				</div>
				<div
					v-if="dropdown || options?.length"
					class="ml-2"
				>
					<font-awesome-icon
						v-if="!disabled"
						icon="chevron-down"
						class="transition-all"
						:class="{
							'rotate-180': computedExpanded,
						}"
					/>
				</div>
			</div>
			<div
				v-if="dropdown && computedExpanded"
				class="status-dropdown  absolute w-full z-50 cursor-pointer left-0"
			>
				<ul class="bg-white list-none p-0 flex flex-col items-start rounded-b-2xl shadow-lg overflow-hidden">
					<li
						v-for="option in options"
						:key="option.id ?? option.value" 
						class="statusList w-full flex gap-3 items-center" 
						:class="{
							'bg-blue-100 pointer-events-none' : value?.value === option.value,
						}"
						@click="select(option)" 
					>
						<div
							v-if="option.color"
							class="rounded-full w-5 border border-solid border-gray-300  flex-shrink-0 h-5"
							:style="{'background-color': option.color }"
						/>
						<div
							class="truncate max-w-full text-sm"
							:title="option.label"
						>
							{{option.label}}
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
// TODO provide slot for before-value instead of static template for the color icon

/**
 * @typedef {Object} Item
 * @property {string} label
 * @property {string} value
 * @property {boolean?} disabled
 * @property {color?} string
*/

export default {
	name: "header-menu",
	props: {
		dropdown: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String,
			required: true,
		},
		// use when dropdown:true
		/**
		 * @type {Item}
		 */
		value: {
			type: Object,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		// force expanded state
		expanded: {
			type: Boolean,
		},
		/**
		 * @type {Item[]}
		 */
		options: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isExpanded: !!this.expanded, // initial value
		}
	},
	computed: {
		computedExpanded() {
			return this.expanded ?? this.isExpanded;
		},
	},
	methods: {
		expand() {
			this.isExpanded = true;
			this.$emit('open');
		},
		collapse() {
			this.isExpanded = false;
			this.$emit('close');
		},
		select(option) {
			this.$emit('input', option);
			this.collapse();
		},
		getStyle(value) {
			if(value.type === 'score') {
				return `color: ${value.color}`
			} else {
				return '';
			}
		}
	},
};
</script>

<style lang="scss" scoped>
</style>