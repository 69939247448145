import Cross from "@/assets/icons/svg/close-icon-white.svg";
import { mapGetters, mapActions } from "vuex";
import {
  HeaderLinkCard,
  // HeaderCodeCard,
  HeaderStatusCard,
  HeaderCreditComponent,
  HeaderGraph,
  HeaderMenu,
} from "./components";
import ModalContent from "@shared/modal-content"
import Input from "@/components/input"
import axios from "@/axios";
// import backendaxios from "../light-ray/axios";
import PendingActions from "@shared/components/pending-actions"
import { DateTime } from "luxon";
import LihgtRayHeaderGraph from "@shared/assets/lgtr-header-graph.svg"
import EditPencil from "@shared/assets/edit-pencil.svg"
import {
  getEntityTypeName,
  checkPermission,
} from "@shared/utils/functions";
import LangDropdown from "@shared/components/LangDropdown";

const headerItemConfig = {
  text: HeaderLinkCard,
  menu: HeaderMenu,
}

export default {
  name: "app-header",
  components: {
    Cross,
    HeaderLinkCard,
    // HeaderCodeCard,
    ModalContent,
    Input,
    PendingActions,
    HeaderStatusCard,
    LihgtRayHeaderGraph,
    EditPencil,
    HeaderCreditComponent,
    HeaderGraph,
    LangDropdown,
  },
  props: {
    notificationCount: {
      type: Number,
      default: 0
    },
    showNotifications: {
      type: Boolean,
      default: true
    },
    statusUpdatePermission:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      headerItemConfig,
      caseName: '',
      caseDescription: "",
      review_date: "",
      onHold: "",
      onHoldDate: "",
      reason: "",
      caseStatus: this.getCaseStatusList,
      activeState: null,
      statusMenuOpen: false,
      showStatus: false,
      duration: 0,
      creditScore: "100",
      listData: [],
      primaryCreditPack: null,
      adminUser: false,
      isChangeCaseStatus: false,
      oldStatusValue: {},
      previousCaseStatus: false,
      creditDataLoading: false,
      // showDuration: false
    };
  },
  computed: {
    ...mapGetters([
      "getCaseData",
      "getUser",
      "getCaseStatusList",
      "getTenantId",
      "getLoginTenantDetails",
      "getCustomHeader",
      "getCaseAccessible",
      "getCaseEntitiesData",
    ]),
    currentCheckStatus() {
      return this.getCaseStatusList.find(x => x.current) || {};
    }, 
    creditlist() {
      return this.listData;
    },
    userName() {
      return this.getUser ? this.getUser.user_name : ' '
    },
    tenantData() {
      return this.getTenantId ? this.getTenantId : ''
    },
    appTitle() {
      return this.getCustomHeader
        ? this.getCustomHeader.title
        : (this.$t(this.$route.meta.tTitle) || '')
    },
    showCaseInfo() {
      return this.$route.meta.showCaseInfo
    },
    caseId() {
      return this.$route.query.case_id
    },
    showLightRay() {
      return this.$route.meta.showLightRay
      // return true;
    },
    getButtonInfoText() {
      return "Save Case status"
    },
    getDaysRemaing() {
      if (this.getCaseData.due_date) {
        const formatDate = (date) => DateTime.fromISO(date).toFormat("dd MMM yyyy");
        let day_remaining = 0
        let today = new Date()
        let due_date = new Date(this.getCaseData.due_date)
        day_remaining = Math.ceil((due_date.getTime() - today.getTime()) / (1000 * 3600 * 24))
        this.getCaseData.due_date = formatDate(this.getCaseData?.due_date);
        if (day_remaining > 0) return day_remaining + ' Days'
        else return `Already passed ${Math.abs(day_remaining)} days ago`
      } else return '--'
    }, 
    filteredStatusList() {
      return this.getCaseStatusList.filter(x => !x.current)
    },
    caseAccessible() {
      return this.getCaseAccessible
    },
    getFirstEntityName() {
      if(this.getCaseData?.case_name) {
        return this.getCaseData?.case_name;
      }
      try {
        let name = "";
        if(this.getCaseEntitiesData?.case_entities?.length){
          const { case_entities } = this.getCaseEntitiesData;
          const caseEntity = case_entities?.find((entity) => {
            const entityType = this.getEntityTypeName(this.getCaseEntitiesData, entity);
            return entityType === "Person" || entityType === "Company";
          });
          name =  caseEntity.entity_name;
        }
        return name;
      } catch(err) {
        console.log("error in finding the first entity name.")
      }

    }
  },
  async mounted() {
    await this.fetchCaseDetails();
    if(this.caseAccessible) {
      await this.fetchTenantDetails();
    }
    await this.getCredit();
  },
  watch: {
    showCaseInfo: async function (val) {
      if (val) {
        this.fetchCaseDetails();
      }
    },
    tenantData: async function (val) {
      if (val) {
        this.fetchTenantDetails();
      }
    },
    activeState(newVal, oldVal) {
      this.oldStatusValue = oldVal;
    }
  },
  methods: {
    ...mapActions(["fetchCaseData", "fetchCaseStatusLists", "setTenantDetails", "setpoweredLogo"]),

    getEntityTypeName,
    checkPermission,

    async refreshCreditPacks() {
        await this.getCredit();
    },

    async getCredit() {
      this.listData = [];
      this.creditDataLoading = true;
      const url = "user-credit-pack/get_user_credit_packs"
      const data = await axios.get(url);
      this.listData = data.data;
      this.primaryCreditPack = this.listData.find((item) => item.primary === true) || null;
      this.creditDataLoading = false;
      return data;
    },
    getCaseName(){
      if(this.$route.name === "Create Case") {
        return ""
      } else {
        return sessionStorage.getItem('case_name') || this.$route.query.name || ""

      }
    },
    handleCloseModal(status_value) {
      this.$refs['header-modal'].hideModal(status_value);
    },
    async fetchTenantDetails() {
      try {
        if (this.getTenantId) {
          const tenantData = await axios.get(`/tenant/${this.getTenantId}`);
          this.adminUser = tenantData?.data?.is_admin;
          this.$store.dispatch("setpoweredLogo", tenantData?.data?.powered_by);
          this.$store.dispatch("setTenantDetails", tenantData?.data);
        }
      } catch (error) {
        if (Array.isArray(error?.response?.data?.detail)) {
          this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
        } else {
          this.$toast.error(error?.response?.data?.detail);
        }
      }
    },
    async fetchCaseDetails(){
        let case_id = this.$route.query.caseid
        if(case_id){
          try {
            await this.fetchCaseData(case_id)
            await this.fetchCaseStatusLists({ case_id })
            this.activeState = this.currentCheckStatus || {};
          } catch(err) {
            this.$toast.error(err?.response?.data?.detail || this.$t("general.default_error"));
          } 
            
        }
    },
    handleShowModal(value) {
      // switch (value) {
      //   case "onHold":
      //     this.$refs['head-modal'].show(value);
      //     break;
      //   case "resume":
      //     this.$refs['head-modal'].show(value);
      //     break;
      //   case "rejectCase":
      //     this.$refs['head-modal'].show(value);
      //     break;
      //   default:
      //     console.log(`Sorry, we are out of ${value}.`);
      // }
      if (value) {
        this.reason = ""
        this.$refs['header-modal'].showModal(value);
      } else return
    },
    setActiveStatus(status) {
      this.activeState = status;
    },
    async handleStatusClick(status) {
        this.previousCaseStatus = false;
        this.setActiveStatus(status)
        this.setShowStatus()
        if(status.reason_mandatory){
          this.handleShowModal(status.name) 
        }else{
            await this.handleChangeCaseStatus(status)
        }
    },

    async handleChangeCaseStatus(status){
      this.previousCaseStatus = false;
        if(this.reason === '' && status.reason_mandatory ){
            this.$toast.error('reason required');
            return
        } else if ( this.duration <= 0 && status.internal_name === 'ON_HOLD') {
          this.$toast.error('Please enter duration in days (must be greater than zero)');
          return
        }
        else{
            let case_id = this.$route.query.caseid
            const url = `/workflow/${case_id}/status/${status.status_id}`//`/case/${case_id}/status`;

            try {
                let { data } = await axios.post(url/*, payload*/);
                await this.fetchCaseStatusLists({ case_id })
                this.$toast.success(data.message || 'case status updated');
                this.setShowStatus()
                this.handleCloseModal()
                this.reason = ""
                this.duration = 0
            } catch (error) {
                this.$toast.warning(error.response.message || 'failed to change case status');
            } 
          }
   
      },

    clickOutside() {
      this.showStatus = false
    },

    setShowStatus() {
      this.showStatus = !this.showStatus;
    },
    logout() {
      this.$store.dispatch("logoutUser");
    },
    setPreviousCaseStatus(val) {
      val ? this.previousCaseStatus = val : this.previousCaseStatus = false
    }
  // created(){
  //     this.caseName = localStorage.getItem("casename")||'';
  // }
  }
};
